import {
	Avatar,
	Button,
	Circle,
	Heading,
	Theme,
	XStack,
	useMedia,
} from "@mps/ui";
import { Link, TextLink } from "solito/link";

import { api } from "@mps/pro-app/provider/react-query";
// import { useAuth } from '@clerk/nextjs'
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
// import Link from 'next/Link'
import { Bell } from "@tamagui/lucide-icons";
import { LogOut } from "@tamagui/lucide-icons";
import { useRouter } from "next/router";
import { Suspense } from "react";
import { Container } from "./Container";
import { Logo } from "./Logo";

export const HEADER_HEIGHT = 82;

const NotifsCount = () => {
	const [notifsCount] = api.notification.count.useSuspenseQuery();

	if (notifsCount === 0) return null;

	return (
		<Link href="/demandes-soins?status=en-attente">
			<XStack
				ai="center"
				space="$2"
				position="relative"
				pr="$3"
				pt="$3"
				animation="bouncy"
				hoverStyle={{ scale: 0.96 }}
			>
				<Bell color="$color11" />
				<Circle
					theme="red"
					ai="center"
					jc="center"
					size="$1.5"
					bg="$color7"
					position="absolute"
					top={0}
					right={0}
					bottom={0}
					pt={2}
				>
					<Heading fontSize="$4" fontWeight="600" color="white">
						{notifsCount}
					</Heading>
				</Circle>
			</XStack>
		</Link>
	);
};

const UserAvatar = () => {
	const [user] = api.user.current.useSuspenseQuery();

	return (
		<>
			<Avatar.Image source={undefined} />
			<Avatar.Fallback bc="$color4" ai="center" jc="center" height="100%">
				<Heading tag="span" size="$7" fontWeight="700">
					{user?.firstName || user?.lastName ? (
						<>
							{user?.firstName?.[0]?.toUpperCase()}
							{user?.lastName?.[0]?.toUpperCase()}
						</>
					) : (
						user?.email?.[0]?.toUpperCase()
					)}
				</Heading>
			</Avatar.Fallback>
		</>
	);
};

const RightMenu = () => {
	const user = useUser();
	const supabaseClient = useSupabaseClient();
	const { pathname } = useRouter();

	return (
		<XStack space="$4" ai="center" py="$4">
			{!!user &&
				!pathname.includes("/inscription") &&
				!pathname.includes("/connexion") &&
				!pathname.includes("/inscription") && (
					<Suspense fallback={null}>
						<NotifsCount />
					</Suspense>
				)}
			{!!user && !pathname.includes("/inscription") && (
				<XStack ai="center" jc="center">
					<TextLink href="/profil">
						<Button circular chromeless py={0} px={0}>
							<XStack space="$3" ai="center" jc="center">
								<Avatar circular size="$4">
									<Suspense fallback={null}>
										<UserAvatar />
									</Suspense>
								</Avatar>
							</XStack>
						</Button>
					</TextLink>
				</XStack>
			)}
			{!!user && pathname.includes("/inscription") && (
				<XStack ai="center" jc="center" theme="red">
					<Button
						circular
						py={0}
						px={0}
						scaleIcon={1.5}
						icon={LogOut}
						onPress={() => {
							supabaseClient.auth.signOut().then(() => {
								window.open("/connexion", "_self");
							});
						}}
					/>
				</XStack>
			)}
		</XStack>
	);
};

export const Header = ({ hasLogo = false, hasAvatar = false, ...rest }) => {
	const media = useMedia();
	return (
		// @ts-ignore
		<XStack
			// position="fixed"
			top={0}
			left={0}
			right={0}
			height={HEADER_HEIGHT}
			{...rest}
		>
			<Container jc="center" $sm={{ px: "$4" }}>
				<XStack ai="center" jc="space-between" fw="wrap">
					<XStack>
						{hasLogo && (
							// @ts-ignore
							<Link href="/" viewProps={{ style: { textDecoration: "none" } }}>
								<Heading tag="span" fontSize="$10">
									<XStack ai="center" space="$2">
										<Logo size={42} />
										{media.gtSm && (
											<Heading
												fontSize="$8"
												fontWeight="600"
												cursor="pointer"
												color="$brand"
											>
												Mon Petit Soin
											</Heading>
										)}
									</XStack>
								</Heading>
							</Link>
						)}
					</XStack>
					<Suspense fallback={null}>
						<RightMenu />
					</Suspense>
				</XStack>
			</Container>
		</XStack>
	);
};
